import React, {Component} from "react";
import "lightgallery.js/dist/css/lightgallery.css";
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";

const GROUP1 = [
    "https://diamanti.s3.amazonaws.com/docs/siic/delhi/1.jpg",
    "https://diamanti.s3.amazonaws.com/docs/siic/delhi/2.jpg",
    "https://diamanti.s3.amazonaws.com/docs/siic/delhi/3.jpg",
    "https://diamanti.s3.amazonaws.com/docs/siic/delhi/4.jpg",
    "https://diamanti.s3.amazonaws.com/docs/siic/delhi/5.jpg",
    "https://diamanti.s3.amazonaws.com/docs/siic/delhi/6.jpg",
    "https://diamanti.s3.amazonaws.com/docs/siic/delhi/7.jpg",
    "https://diamanti.s3.amazonaws.com/docs/siic/delhi/8.jpg",
    "https://diamanti.s3.amazonaws.com/docs/siic/delhi/9.jpg",
    "https://diamanti.s3.amazonaws.com/docs/siic/delhi/10.jpg",
    "https://diamanti.s3.amazonaws.com/docs/siic/delhi/11.jpg",
    "https://diamanti.s3.amazonaws.com/docs/siic/delhi/12.jpg",
    "https://diamanti.s3.amazonaws.com/docs/siic/delhi/13.jpg",
    "https://diamanti.s3.amazonaws.com/docs/siic/delhi/14.jpg",
    "https://diamanti.s3.amazonaws.com/docs/siic/delhi/15.jpg",
    "https://diamanti.s3.amazonaws.com/docs/siic/delhi/16.jpg"
  ];

  const GROUP2 = [
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/1.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/2.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/3.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/4.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/5.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/6.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/7.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/8.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/9.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/10.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/11.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/12.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/13.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/14.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/15.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/16.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/17.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/18.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/19.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/20.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/21.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/22.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/23.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/24.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/25.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/26.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/27.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/28.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/29.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/30.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/31.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/32.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/33.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/34.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/35.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/36.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/37.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/38.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/39.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/40.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/1_1.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/1_2.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/1_3.jpg",
      "https://diamanti.s3.amazonaws.com/docs/siic/hkhub/1_4.jpg",
  ];
// const Image = [
//     "https://diamanti.s3.amazonaws.com/docs/siic/delhi/thumb/1.jpg",
//     "https://diamanti.s3.amazonaws.com/docs/siic/delhi/thumb/2.jpg",
//     "https://diamanti.s3.amazonaws.com/docs/siic/delhi/thumb/3.jpg",
//     "https://diamanti.s3.amazonaws.com/docs/siic/delhi/thumb/4.jpg",
//     "https://diamanti.s3.amazonaws.com/docs/siic/delhi/thumb/5.jpg",
//     "https://diamanti.s3.amazonaws.com/docs/siic/delhi/thumb/6.jpg",
//     "https://diamanti.s3.amazonaws.com/docs/siic/delhi/thumb/7.jpg",
//     "https://diamanti.s3.amazonaws.com/docs/siic/delhi/thumb/8.jpg",
//     "https://diamanti.s3.amazonaws.com/docs/siic/delhi/thumb/9.jpg",
//     "https://diamanti.s3.amazonaws.com/docs/siic/delhi/thumb/10.jpg",
// ]
const PhotoItem = ({ image, group }) => (
   <>
    <div className="photoItems">
        <LightgalleryItem group={group} src={image}>
            <img src={image} style={{ width: "100%" }} />
        </LightgalleryItem>
    </div>
    </>
  );

class Siic extends Component {
	render() {
		return (
			<div className="photosContainer">
                <div className="mb-50">
                    <h1 className="mainTitle">SKILL INDIA INCENTIVE CEREMONY - 2018 </h1>
                    <h2 className="subSmallTitlle">Videos</h2>
                    <div className="photoWrapper">
                        <div className="photoItems">
                            <a href="https://www.youtube.com/watch?v=UnUjz7jfAo0">
                                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/UnUjz7jfAo0?autoplay=0" allowfullscreen width="100%" height="auto" frameborder="0" gesture="media" allow="encrypted-media" ></iframe>
                            </a>
                            <div className="photoTitle">"SKILL INDIA INCENTIVE CEREMONY - 4th November, Mumbai</div>
                        </div>
                        <div className="photoItems">
                            <a href="https://www.youtube.com/watch?v=sy8TuMUGYns">
                                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/sy8TuMUGYns?autoplay=0" allowfullscreen width="100%" height="auto" frameborder="0" gesture="media" allow="encrypted-media" ></iframe>
                            </a>
                            <div className="photoTitle">"HK SKILL INDIA INCENTIVE CEREMONY" - Highlight</div>
                        </div>
                        <div className="photoItems">
                            <a href="https://www.youtube.com/watch?v=toA2FVyM5W0">
                                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/toA2FVyM5W0?autoplay=0" allowfullscreen width="100%" height="auto" frameborder="0" gesture="media" allow="encrypted-media"  ></iframe>
                            </a>    
                            <div className="photoTitle">Hon'ble PM of India <br />Shri Narendrabhai Modi <br />at "HK Group SKILL INDIA INCENTIVE CEREMONY" 2018</div>
                        </div>
                        <div className="photoItems">
                            <a href="https://www.youtube.com/watch?v=SQ6fsV4NNQQ">
                                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/SQ6fsV4NNQQ?autoplay=0" allowfullscreen width="100%" height="auto" frameborder="0" gesture="media" allow="encrypted-media" ></iframe>
                            </a>
                            <div className="photoTitle">"HK Group SKILL INDIA INCENTIVE CEREMONY" 2018 (FULL EVENT)</div>
                        </div>
                        <div className="photoItems">
                            <a href="https://www.youtube.com/watch?v=ds15FXtE40Q">
                                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ds15FXtE40Q?autoplay=0" allowfullscreen width="100%" height="auto" frameborder="0" gesture="media" allow="encrypted-media" ></iframe>
                            </a>
                            <div className="photoTitle">"વિશ્વવંદનિય પ.પૂ. મોરારીબાપૂના આર્શીવચન at HK GROUP SIIC 2018"</div>
                        </div>
                        <div className="photoItems">
                            <a href="https://www.youtube.com/watch?v=6iDS3bH8ias">
                                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/6iDS3bH8ias?autoplay=0" allowfullscreen width="100%" height="auto" frameborder="0" gesture="media" allow="encrypted-media" ></iframe>
                            </a>
                            <div className="photoTitle">"શ્રી શ્રી રવિશંકર મહારાજના આર્શીવચન at HK GROUP SIIC 2018"</div>
                        </div>
                        <div className="photoItems">
                            <a href="https://www.youtube.com/watch?v=C55YXLgVNAk">
                                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/C55YXLgVNAk?autoplay=0" allowfullscreen width="100%" height="auto" frameborder="0" gesture="media" allow="encrypted-media" ></iframe>
                            </a>
                            <div className="photoTitle">"ભાગવત ભાસ્કર પૂ. ભાઈશ્રી રમેશભાઈ ઓઝાના આર્શીવચન at HK GROUP SIIC 2018"</div>
                        </div>
                    </div>
                
                </div>
                <LightgalleryProvider
                    onAfterSlide={() => {
                        // console.log("onAfterSlide");
                    }}
                    >
                    <div  className="mb-50">
                    <h2 className="subSmallTitlle">Hon'ble Prime Minister of India, Shri Narendrabhai Modi handing over car keys in Delhi to 2 employees of Hari Krishna Exports under the <b>SKILL INDIA INCENTIVE CEREMONY"</b>"</h2>
                    <div className="photoWrapper">
                        {GROUP1.map((p, idx) => (
                            <PhotoItem key={idx} image={p} group="group1" />
                        ))}
                    </div>
                </div>
                
                    <div>
                    <h2 className="subSmallTitlle"><b>"SKILL INDIA INCENTIVE CEREMONY"</b> at HK HUB, SURAT - 25-10-2018</h2>
                    <div className="photoWrapper">
                        {GROUP2.map((p, idx) => (
                            <PhotoItem key={idx} image={p} group="group2" />
                        ))}
                    </div>
                    <div className="photoWrapper">
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/1.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/2.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/3.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/4.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/5.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/6.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/7.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/8.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/9.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/10.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/11.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/12.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/13.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/14.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/15.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/16.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/17.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/18.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/19.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/20.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/21.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/22.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/23.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/24.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/25.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/26.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/27.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/28.jpg" />
                        </a>
                        </div>

                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/29.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/30.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/31.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/32.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/33.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/34.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/35.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/36.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/37.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/38.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/39.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/40.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/1_1.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/1_2.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/1_3.jpg" />
                        </a>
                        </div>
                        <div className="photoItems">
                        <a href="">
                            <img class="img-responsive" src="https://diamanti.s3.amazonaws.com/docs/siic/hkhub/thumb/1_4.jpg" />
                        </a>
                        </div>
                    </div>
                    </div>
                </LightgalleryProvider>
            </div>
		);
	}
}

export default Siic;
